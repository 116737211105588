@import '../imports';


// E-Mail Share Link
a.pswp__share--mail:hover {
  background: #DDD;
}


// Refine Caption Styling
.pswp__caption__center {
  text-align: center;
  max-width: 80%;
  max-width: 60ch;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.pswp__caption h1 {
  @include font-body;
  font-size: 1.3em;
  margin-top: 0;
}
.pswp__caption p {
  font-size: 1em;
  opacity: .8;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.pswp__caption small {
  p {
  margin: 0;
  font-style: italic;
}
a {
  color: inherit;
}
}
